import React from 'react'
import Typography from '@material-ui/core/Typography'
import { css } from '@emotion/core'

import { formatPercentage } from '../util'

const titleStyle = css`
  font-size: 14px;
`

export const Statistic = ({ text, title, textProps = {} }) => (
  <>
    <Typography css={titleStyle} color="textSecondary" gutterBottom>
      {title}
    </Typography>
    <Typography variant="h3" {...textProps}>
      {text}
    </Typography>
  </>
)
Statistic.defaultProps = {
  text: '',
  title: '',
  textProps: {},
}

export const StatisticPercentage = ({ value, title, textProps = {} }) => {
  const perc = formatPercentage(value)

  return <Statistic text={perc} title={title} textProps={textProps} />
}